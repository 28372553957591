var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-user"}),_vm._v(" Dados Pessoais ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Nome do agente"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.name.$error },attrs:{"state":_vm.$v.payload.name.$dirty ?
              !_vm.$v.payload.name.$error : null},model:{value:(_vm.$v.payload.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.name, "$model", $$v)},expression:"$v.payload.name.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.email.$error },attrs:{"state":_vm.$v.payload.email.$dirty ?
              !_vm.$v.payload.email.$error : null},model:{value:(_vm.$v.payload.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.email, "$model", $$v)},expression:"$v.payload.email.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Documento (CPF ou CNPJ)"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],class:{ 'form-group--error': _vm.$v.payload.cpf.$error },attrs:{"state":_vm.$v.payload.cpf.$dirty ?
              !_vm.$v.payload.cpf.$error : null},model:{value:(_vm.$v.payload.cpf.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.cpf, "$model", $$v)},expression:"$v.payload.cpf.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Celular"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) # ####-####'),expression:"'(##) # ####-####'"}],class:{ 'form-group--error': _vm.$v.payload.phone_mobile.$error },attrs:{"state":_vm.$v.payload.phone_mobile.$dirty ?
              !_vm.$v.payload.phone_mobile.$error : null},model:{value:(_vm.$v.payload.phone_mobile.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.phone_mobile, "$model", $$v)},expression:"$v.payload.phone_mobile.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Fone Fixo"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##)####-####'),expression:"'(##)####-####'"}]})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Arquivo (CPF ou RG)"}},[_c('b-form-file',{attrs:{"browse-text":"Procurar","placeholder":"Selecione o arquivo ou arraste e solte aqui","drop-placeholder":"Solte o arquivo aqui"},on:{"change":_vm.setArchive}})],1)],1),(_vm.file)?_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"lg":"3"}},[_c('a',{staticClass:"text-dark text-center d-inline-block",attrs:{"href":(_vm.api + "files/" + (_vm.file.path) + "/" + (_vm.file.filename))}},[_c('i',{staticClass:"fas fa-file-download"}),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.file.filename)+" ")])]):_vm._e()],1)],1)],1)]),_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-home"}),_vm._v(" Endereço ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"CEP"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],model:{value:(_vm.payload.address.zip_code),callback:function ($$v) {_vm.$set(_vm.payload.address, "zip_code", $$v)},expression:"payload.address.zip_code"}})],1)],1),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Rua"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.address.street.$error },attrs:{"state":_vm.$v.payload.address.street.$dirty ?
              !_vm.$v.payload.address.street.$error : null},model:{value:(_vm.$v.payload.address.street.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.address.street, "$model", $$v)},expression:"$v.payload.address.street.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-group',{attrs:{"label":"Número"}},[_c('b-form-input',{model:{value:(_vm.payload.address.number),callback:function ($$v) {_vm.$set(_vm.payload.address, "number", $$v)},expression:"payload.address.number"}})],1)],1),_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',{attrs:{"label":"Bairro"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.address.neighborhood.$error },attrs:{"state":_vm.$v.payload.address.neighborhood.$dirty ?
              !_vm.$v.payload.address.neighborhood.$error : null},model:{value:(_vm.$v.payload.address.neighborhood.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.address.neighborhood, "$model", $$v)},expression:"$v.payload.address.neighborhood.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":""}},[_c('b-form-group',{attrs:{"label":"Estado"}},[_c('b-form-select',{class:{ 'form-group--error': _vm.$v.payload.address.province.$error },attrs:{"state":_vm.$v.payload.address.province.$dirty ?
              !_vm.$v.payload.address.province.$error : null,"options":_vm.provinces},on:{"change":_vm.getCities},model:{value:(_vm.$v.payload.address.province.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.address.province, "$model", $$v)},expression:"$v.payload.address.province.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":""}},[_c('b-form-group',{attrs:{"label":"Cidade"}},[_c('Autocomplete',{ref:"cities",attrs:{"source":_vm.cities,"input-class":"form-control","disableInput":!_vm.cities.length,"resultsValue":"id","placeholder":"Selecione primeiro o estado"},on:{"selected":_vm.setCity}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1)],1)]),_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-money-check-alt"}),_vm._v(" Dados bancários ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":""}},[_c('b-form-select',{class:{ 'form-group--error': _vm.$v.payload.bank_account.person_type.$error },attrs:{"state":_vm.$v.payload.bank_account.person_type.$dirty ?
            !_vm.$v.payload.bank_account.person_type.$error : null,"options":_vm.person_type},model:{value:(_vm.$v.payload.bank_account.person_type.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.bank_account.person_type, "$model", $$v)},expression:"$v.payload.bank_account.person_type.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Banco"}},[_c('Autocomplete',{ref:"autocomplete",attrs:{"source":_vm.optionsBanks,"results-display":_vm.formattedDisplayListBanks,"input-class":"form-control","initialDisplay":_vm.payload.bank_account.bank_name,"initialValue":_vm.payload.bank_account.bank_code,"resultsValue":"code","placeholder":"Pesquisar banco"},on:{"selected":_vm.setBank}}),_vm._v(" "+_vm._s(_vm.payload.bank_account.bank_name)+" ")],1)],1),_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-group',{attrs:{"label":"Agência com digíto"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.bank_account.agency.$error },attrs:{"state":_vm.$v.payload.bank_account.agency.$dirty ?
            !_vm.$v.payload.bank_account.agency.$error : null},model:{value:(_vm.$v.payload.bank_account.agency.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.bank_account.agency, "$model", $$v)},expression:"$v.payload.bank_account.agency.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Conta"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.bank_account.account.$error },attrs:{"state":_vm.$v.payload.bank_account.account.$dirty ?
            !_vm.$v.payload.bank_account.account.$error : null},model:{value:(_vm.$v.payload.bank_account.account.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.bank_account.account, "$model", $$v)},expression:"$v.payload.bank_account.account.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1)],1),_c('div',{staticClass:"w-100 text-right mt-5"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("Salvar")]),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("Salvar e criar pré projeto")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }